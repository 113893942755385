<template>
  <v-container fluid class="" v-if="getPermission('setting:view')">
    <v-layout>
      <v-flex>
        <h3
          class="font-weight-900 mb-4 font-size-36 custom-headline color-custom-blue"
        >
          Opportunity
        </h3>
      </v-flex>
      <v-flex class="text-right">
        <v-btn
          class="custom-grey-border custom-bold-button"
          v-on:click="goBack"
        >
          <v-icon small left>mdi-keyboard-backspace</v-icon>
          Back
        </v-btn>
      </v-flex>
    </v-layout>
    <perfect-scrollbar
      :options="{ suppressScrollX: true }"
      class="scroll custom-box-top-inner-shadow"
      style="max-height: 85vh; position: relative"
    >
      <v-card flat class="custom-grey-border remove-border-radius mt-4">
        <v-card-title class="headline grey lighten-4">
          <v-row>
            <v-col md="4" class="py-0 my-auto">
              <h3
                class="font-weight-700 custom-headline color-custom-blue"
                style="margin: 3.5px 0px"
              >
                Lead Source
              </h3>
            </v-col>
            <template v-if="getPermission('setting:update')">
              <v-col md="8" class="text-right py-0">
                <v-btn
                  small
                  class="mx-4 white--text"
                  v-on:click="createJobType"
                  color="cyan"
                >
                  <span class="font-size-16 font-weight-600"
                    >Create Source</span
                  >
                </v-btn>
                <v-btn
                  small
                  class="text-white"
                  color="green"
                  v-on:click="updateLeadSource"
                  :disabled="pageLoading"
                  :loading="pageLoading"
                >
                  <span class="font-size-16 font-weight-600">Update</span>
                </v-btn>
              </v-col>
            </template>
          </v-row>
        </v-card-title>
        <v-card-text
          class="p-6 font-size-16"
          :class="{ 'pointer-events-none': !getPermission('setting:update') }"
        >
          <v-row>
            <v-col md="12" class="pb-4">
              <table class="width-100 mt-1 mb-2">
                <tbody>
                  <tr v-for="(type, index) in leadSourceList" :key="index">
                    <td>
                      <v-text-field
                        v-model.trim="type.text"
                        dense
                        filled
                        label="Lead Source"
                        solo
                        flat
                        class="px-4 py-1"
                        hide-details
                        :loading="pageLoading"
                        color="cyan"
                      ></v-text-field>
                    </td>
                    <td width="50" valign="top" class="pt-4">
                      <v-tooltip top content-class="custom-top-tooltip">
                        <template v-slot:activator="{ on, attrs }">
                          <v-icon
                            v-on:click="deleteJobType(index)"
                            color="deep-orange"
                            v-bind="attrs"
                            v-on="on"
                            :disabled="type.is_exist ? true : false"
                            >mdi-delete</v-icon
                          >
                        </template>
                        <span>Click here to delete</span>
                      </v-tooltip>
                    </td>
                  </tr>
                </tbody>
              </table>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </perfect-scrollbar>
  </v-container>
</template>
  
  <script>
import { GET, PATCH } from "@/core/services/store/request.module";
import ValidationMixin from "@/core/plugins/validation-mixin";

export default {
  name: "profile-job-setting",
  mixins: [ValidationMixin],

  data() {
    return {
      pageLoading: false,
      formValid: true,
      app_setting: [],
      leadSourceList: [
        {
          text: null,
        },
      ],
    };
  },
  components: {},
  methods: {
    getLeadSources() {
      const _this = this;
      _this.$store
        .dispatch(GET, { url: "setting/lead-source" })
        .then(({ data }) => {
          if (Array.isArray(data.lead_source) && data.lead_source.length) {
            _this.leadSourceList = data?.lead_source;
          }
        })
        .catch((error) => {
          _this.logError(error);
        })
        .finally(() => {
          _this.pageLoading = false;
        });
    },
    updateLeadSource() {
      const _this = this;
      _this.dataLoading = true;
      _this.$store
        .dispatch(PATCH, {
          url: "setting/lead-source",
          data: {
            lead_source: _this.leadSourceList,
          },
        })
        .then(() => {
          _this.getLeadSources();
        })
        .catch((error) => {
          _this.logError(error);
        })
        .finally(() => {
          _this.dataLoading = false;
        });
    },

    createJobType() {
      this.leadSourceList.push({
        text: null,
        is_active: 1,
      });
    },
    deleteJobType(index) {
      if (this.leadSourceList.length > 1) {
        this.leadSourceList.splice(index, 1);
      }
    },
  },
  mounted() {
    this.getLeadSources();
  },
};
</script>
  