<template>
  <v-container fluid class="width-100" v-if="getPermission('setting:view')"
    ><v-layout>
      <v-flex>
        <h3
          class="font-weight-900 mb-4 font-size-36 custom-headline color-custom-blue"
        >
          Billing Location
        </h3>
      </v-flex>
      <v-flex class="text-right">
        <v-btn
          depressed
          class="custom-grey-border custom-bold-button"
          v-on:click="goBack"
        >
          <v-icon small left>mdi-keyboard-backspace</v-icon>
          Back
        </v-btn>
      </v-flex>
    </v-layout>
    <v-row>
      <v-col md="10">
        <perfect-scrollbar
          :options="{ suppressScrollX: true }"
          class="scroll custom-box-top-inner-shadow"
          style="max-height: 85vh; position: relative"
        >
          <v-card
            flat
            class="custom-setting-height custom-grey-border remove-border-radius"
          >
            <v-card-title class="headline grey lighten-4">
              <v-row>
                <v-col md="6" class="py-0 my-auto">
                  <h3
                    class="font-weight-700 custom-headline color-custom-blue"
                    style="margin: 3.5px 0px"
                  >
                    Billing Location #
                  </h3>
                </v-col>
                <template v-if="getPermission('setting:update')">
                  <v-col md="6" class="text-right py-0">
                    <v-btn
                      depressed
                      small
                      class="text-white"
                      color="cyan"
                      v-on:click="updatePropertySetting"
                      :disabled="pageLoading"
                      :loading="pageLoading"
                    >
                      <span class="font-size-16 font-weight-600">Update</span>
                    </v-btn>
                  </v-col>
                </template>
              </v-row>
            </v-card-title>
            <v-card-text
              class="p-6 font-size-16"
              :class="{
                'pointer-events-none': !getPermission('setting:update'),
              }"
            >
              <v-row>
                <v-col md="2" class="pb-4">
                  <label>Prefix</label>
                  <v-text-field
                    v-model.trim="setting.prefix"
                    dense
                    filled
                    placeholder="Prefix"
                    solo
                    flat
                    hide-details
                    :disabled="pageLoading"
                    :loading="pageLoading"
                    color="cyan"
                  ></v-text-field>
                </v-col>
                <v-col md="2" class="pb-4">
                  <label>Next Number</label>
                  <v-text-field
                    v-model.trim="setting.next_number"
                    dense
                    filled
                    placeholder="Next Number"
                    solo
                    flat
                    type="number"
                    min="0"
                    max="999"
                    hide-details
                    :disabled="pageLoading"
                    :loading="pageLoading"
                    color="cyan"
                  ></v-text-field>
                </v-col>
                <v-col md="2" class="pb-4">
                  <label>Number Length</label>
                  <v-text-field
                    v-model.trim="setting.number_length"
                    dense
                    filled
                    placeholder="Number Length"
                    solo
                    flat
                    type="number"
                    min="0"
                    max="15"
                    hide-details
                    :disabled="pageLoading"
                    :loading="pageLoading"
                    color="cyan"
                  ></v-text-field>
                </v-col>
              </v-row>
            </v-card-text>
          </v-card>
        </perfect-scrollbar>
        <perfect-scrollbar
          :options="{ suppressScrollX: true }"
          class="scroll custom-box-top-inner-shadow"
          style="max-height: 85vh; position: relative"
          v-if="false"
        >
          <v-card
            flat
            class="custom-grey-border remove-border-radius mt-4"
            v-if="false"
          >
            <v-card-title class="headline grey lighten-4">
              <v-row>
                <v-col md="4" class="py-0 my-auto">
                  <h3
                    class="font-weight-700 custom-headline color-custom-blue"
                    style="margin: 3.5px 0px"
                  >
                    Add/Edit Sub Location
                  </h3>
                </v-col>
                <template v-if="getPermission('setting:update')">
                  <v-col md="8" class="text-right py-0">
                    <div class="d-flex justify-content-end">
                      <v-btn
                        depressed
                        small
                        class="white--text"
                        v-on:click="locationDialogOpen"
                        color="cyan"
                      >
                        <span class="font-size-16 font-weight-600">Create</span>
                      </v-btn>
                    </div>
                  </v-col>
                </template>
              </v-row>
            </v-card-title>
            <v-card-text
              class="p-6 font-size-16"
              :class="{
                'pointer-events-none': !getPermission('setting:update'),
              }"
            >
              <v-row>
                <v-col md="12" class="pb-4">
                  <v-simple-table
                    class="simple-table"
                    fixed-header
                    :height="locationList?.length > 0 ? 'auto' : '100px'"
                  >
                    <template v-slot:default>
                      <thead>
                        <tr>
                          <th class="simple-table-th" width="50">#</th>
                          <th class="simple-table-th" width="500">
                            Sub Location
                          </th>
                          <th class="simple-table-th">Transaction</th>
                          <th class="simple-table-th" width="70">Action</th>
                        </tr>
                      </thead>
                      <tbody v-if="locationList?.length > 0">
                        <tr
                          v-for="(location, index) in locationList"
                          :key="index"
                          class="alternate-listing-row"
                        >
                          <td width="50" class="simple-table-td">
                            {{ index + 1 }}
                          </td>
                          <td
                            width="100"
                            class="simple-table-td text-capitalize"
                          >
                            <template v-if="location?.text">
                              {{ location?.text }}
                            </template>
                            <em v-else class="text-muted"> no text </em>
                          </td>
                          <td class="simple-table-td">
                            <template v-if="location?.total_count">
                              <v-badge
                                color="red"
                                class="mr-9 ml-2"
                                :content="location?.total_count"
                              >
                              </v-badge>
                            </template>
                            <em v-else class="text-muted">
                              <v-badge
                                color="red"
                                class="mr-9 ml-2"
                                content="0"
                              >
                              </v-badge>
                            </em>
                          </td>

                          <td class="simple-table-td">
                            <v-icon
                              color="cyan"
                              size="20"
                              v-on:click="updateLocationType(location)"
                            >
                              mdi-pencil</v-icon
                            >
                            <v-icon
                              color="red"
                              class="ml-2"
                              size="20"
                              v-on:click="deleteLocation(location)"
                            >
                              mdi-delete</v-icon
                            >
                          </td>
                        </tr>
                      </tbody>
                      <tr v-else>
                        <td colspan="5" class="py-4">
                          <p
                            class="m-0 row-not-found text-center font-weight-500 font-size-16"
                          >
                            <img
                              :src="$assetURL('media/error/empty.png')"
                              class="row-not-found-image"
                            />
                            Uhh... There are no sub location at the moment.
                          </p>
                        </td>
                      </tr>
                    </template>
                  </v-simple-table>
                </v-col>
              </v-row>
            </v-card-text>
          </v-card>
          <v-card flat class="custom-grey-border remove-border-radius mt-4">
            <v-card-title class="headline grey lighten-4">
              <v-row>
                <v-col md="4" class="py-0 my-auto">
                  <h3
                    class="font-weight-700 custom-headline color-custom-blue"
                    style="margin: 3.5px 0px"
                  >
                    Add/Edit Sub Location
                  </h3>
                </v-col>
                <template v-if="getPermission('setting:update')">
                  <v-col md="8" class="text-right py-0">
                    <div class="d-flex justify-content-end">
                      <v-btn
                        depressed
                        small
                        class="white--text"
                        v-on:click="locationDialogOpen"
                        color="cyan"
                      >
                        <span class="font-size-16 font-weight-600">Create</span>
                      </v-btn>
                    </div>
                  </v-col>
                </template>
              </v-row>
            </v-card-title>
            <v-card-text
              class="p-6 font-size-16"
              :class="{
                'pointer-events-none': !getPermission('setting:update'),
              }"
            >
              <v-row>
                <v-col md="12" class="pb-4">
                  <v-simple-table
                    class="simple-table"
                    fixed-header
                    style="min-height: 200px"
                    :height="
                      paginatedLocationList?.length > 0 ? 'auto' : '100px'
                    "
                  >
                    <template v-slot:default>
                      <thead>
                        <tr>
                          <th class="simple-table-th" width="50">#</th>
                          <th class="simple-table-th" width="500">
                            Sub Location
                          </th>
                          <th class="simple-table-th">Transaction</th>
                          <th class="simple-table-th" width="70">Action</th>
                        </tr>
                      </thead>
                      <tbody v-if="paginatedLocationList?.length > 0">
                        <tr
                          v-for="(location, index) in paginatedLocationList"
                          :key="index"
                          class="alternate-listing-row"
                        >
                          <td width="50" class="simple-table-td">
                            {{ (currentPage - 1) * itemsPerPage + index + 1 }}
                          </td>
                          <td
                            width="100"
                            class="simple-table-td text-capitalize"
                          >
                            <template v-if="location?.text">
                              {{ location?.text }}
                            </template>
                            <em v-else class="text-muted"> no text </em>
                          </td>
                          <td class="simple-table-td">
                            <template v-if="location?.total_count">
                              <v-badge
                                color="red"
                                class="mr-9 ml-2"
                                :content="location?.total_count"
                              >
                              </v-badge>
                            </template>
                            <em v-else class="text-muted">
                              <v-badge
                                color="red"
                                class="mr-9 ml-2"
                                content="0"
                              >
                              </v-badge>
                            </em>
                          </td>

                          <td class="simple-table-td">
                            <v-icon
                              color="cyan"
                              size="20"
                              v-on:click="updateLocationType(location)"
                            >
                              mdi-pencil</v-icon
                            >
                            <v-icon
                              color="red"
                              class="ml-2"
                              size="20"
                              v-on:click="deleteLocation(location)"
                            >
                              mdi-delete</v-icon
                            >
                          </td>
                        </tr>
                      </tbody>
                      <tr v-else>
                        <td colspan="5" class="py-4">
                          <p
                            class="m-0 row-not-found text-center font-weight-500 font-size-16"
                          >
                            <img
                              :src="$assetURL('media/error/empty.png')"
                              class="row-not-found-image"
                            />
                            Uhh... There are no sub location at the moment.
                          </p>
                        </td>
                      </tr>
                    </template>
                  </v-simple-table>
                </v-col>
              </v-row>
              <v-row
                justify="end"
                class="align-center pagination_btn listing-footer px-2"
              >
                <p class="m-0 listing-footer-count">
                  Showing {{ (currentPage - 1) * itemsPerPage + 1 }} to
                  {{
                    Math.min(currentPage * itemsPerPage, locationList.length)
                  }}
                  of {{ locationList.length }} entries
                </p>
                <v-spacer></v-spacer>
                <v-pagination
                  v-model="currentPage"
                  :length="pageCount"
                  total-visible="7"
                  color="cyan"
                  @input="handlePageChange"
                ></v-pagination>
              </v-row>
            </v-card-text>
          </v-card>

          <Dialog :common-dialog="confirm_dialog" :dialog-width="850">
            <template v-slot:title> Confirmation </template>
            <template v-slot:body>
              <v-form ref="statusForm" v-model.trim="formValid" lazy-validation>
                <v-container class="px-10" fluid>
                  <h3 class="font-weight-500 text-center">
                    Sub Location already exists in another transaction. Any
                    updates will be reflected in a new transaction.
                  </h3>
                </v-container>
              </v-form>
            </template>
            <template v-slot:action>
              <v-btn
                :disabled="confirm_loading"
                v-on:click="confirm_dialog = false"
                class="mx-2 custom-grey-border custom-bold-button"
              >
                No! Close
              </v-btn>
              <v-btn
                v-on:click="updateLocationSetting()"
                :disabled="!formValid || confirm_loading"
                :loading="confirm_loading"
                class="mx-2 custom-bold-button white--text"
                color="cyan"
              >
                Yes! Continue
              </v-btn>
            </template>
          </Dialog>
          <Dialog :commonDialog="locationDialog" :dialog-width="850">
            <template v-slot:title>
              <template v-if="locationPayload?.id > 0">Edit</template
              ><template v-else>Add</template> Sub Location
            </template>
            <template v-slot:body>
              <v-container fluid>
                <v-form
                  ref="ticketCollecttionFormSkills"
                  v-model.trim="formValid"
                  lazy-validation
                  v-on:submit.stop.prevent="updateLocationSetting"
                >
                  <v-row>
                    <v-col md="12">
                      <label class="my-auto fw-500 required mb-1"
                        >Location
                      </label>
                      <TextField
                        id="location"
                        dense
                        filled
                        placeholder="location"
                        solo
                        flat
                        counter="100"
                        v-model.trim="locationPayload.name"
                        color="cyan"
                        :maxlength="250"
                        :rules="[
                          validateRules.required(locationPayload.name, 'Name'),
                        ]"
                      />
                    </v-col>
                  </v-row>
                </v-form>
              </v-container>
            </template>
            <template v-slot:action>
              <v-btn
                depressed
                :loading="pageLoading"
                :disabled="pageLoading || !formValid"
                class="mx-2 custom-grey-border custom-bold-button"
                color="cyan white--text"
                v-on:click="updateLocationSettingType(is_exist)"
              >
                Save
              </v-btn>
              <v-btn
                depressed
                :disabled="pageLoading"
                class="mx-2 custom-grey-border custom-bold-button"
                v-on:click="locationDialog = false"
              >
                Close
              </v-btn>
            </template>
          </Dialog>

          <DeleteDialog :delete-dialog="deleteLocationDialog">
            <template v-slot:title>Delete Confirmation</template>
            <template v-slot:text>
              <h3 class="font-size-18 font-weight-600 delete-text">
                Are you sure you want to delete this sub location ?
              </h3>
            </template>
            <template v-slot:action>
              <v-btn
                :disabled="deleteLocationLoading"
                class="mx-2 custom-grey-border custom-bold-button"
                v-on:click="deleteLocationDialog = false"
                >No! Cancel</v-btn
              >
              <v-btn
                :disabled="deleteLocationLoading"
                :loading="deleteLocationLoading"
                color="red lighten-1 white--text"
                v-on:click="deleteLocationConfirm()"
                class="mx-2 custom-grey-border custom-bold-button"
                >Yes! Delete</v-btn
              >
            </template>
          </DeleteDialog>
        </perfect-scrollbar>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { GET, PATCH, DELETE } from "@/core/services/store/request.module";
import Dialog from "@/view/pages/partials/Dialog.vue";
import ValidationMixin from "@/core/plugins/validation-mixin";
import DeleteDialog from "@/view/pages/partials/Delete";
import { ErrorEventBus, InitializeError } from "@/core/lib/message.lib";
export default {
  name: "profile-property-setting",
  mixins: [ValidationMixin],
  data() {
    return {
      deleteLocationLoading: false,
      pageLoading: true,
      locationPayload: {
        name: null,
        is_active: 1,
      },
      propertPageLoading: false, // or your actual loading state variable
      itemsPerPage: 10,
      location_id: 0,
      currentPage: 1,
      is_exist: 0,
      confirm_dialog: false,
      confirm_loading: false,
      deleteLocationDialog: false,
      locationDialog: false,
      locationList: [],
      dataLoading: false,
      setting: {
        prefix: null,
        next_number: null,
        number_length: null,
        remarks: null,
      },
    };
  },
  components: {
    Dialog,
    DeleteDialog,
  },
  methods: {
    deleteLocation(data) {
      this.location_id = data.id;
      this.is_exist = data.is_exist ? 1 : 0;
      this.deleteLocationDialog = true;
    },
    deleteLocationConfirm() {
      const _this = this;
      _this.deleteLocationLoading = true;
      _this.$store
        .dispatch(DELETE, {
          url: `setting/sub-locations/${this.location_id}`,
        })
        .then(() => {
          _this.deleteLocationLoading = false;
          _this.deleteLocationDialog = false;
          _this.getPropertySetting();
        })
        .catch((error) => {
          _this.logError(error);
          _this.deleteLocationLoading = false;
        });
    },

    updateLocationType(param) {
      const _this = this;
      _this.is_exist = param.is_exist ? 1 : 0;
      _this.locationDialog = true;

      setTimeout(function () {
        _this.locationPayload = {
          id: _this.lodash.toSafeInteger(param.id),
          name: param?.text ? param.text : null,
          is_active: param.is_active ? param.is_active : 1,
        };
      }, 500);
    },
    updateLocationSettingType(type) {
      if (type == 0) {
        this.updateLocationSetting();
      } else {
        this.confirm_dialog = true;
      }
    },
    updateLocationSetting() {
      const _this = this;
      const formErrors = _this.validateForm(
        _this.$refs.ticketCollecttionFormSkills
      );

      if (formErrors.length) {
        for (let i = 0; i < formErrors.length; i++) {
          ErrorEventBus.$emit("update:error", InitializeError(formErrors[i]));
          _this.pageLoading = false;
        }
        return false;
      }

      _this.pageLoading = true;
      _this.dataLoading = true;

      _this.$store
        .dispatch(PATCH, {
          url: "setting/sub-locations",
          data: _this.locationPayload,
        })
        .then(() => {
          _this.getPropertySetting();
        })
        .catch((error) => {
          _this.logError(error);
        })
        .finally(() => {
          _this.dataLoading = false;
          _this.pageLoading = false;
          _this.locationDialog = false;
        });
    },

    locationDialogOpen() {
      this.locationPayload = {
        name: null,
        is_active: 1,
      };
      this.locationDialog = true;
    },
    getPropertySetting() {
      const _this = this;
      _this.$store
        .dispatch(GET, { url: "setting/property" })
        .then(({ data }) => {
          _this.setting = data;
          _this.locationList = data.sub_locations;
          _this.confirm_dialog = false;
        })
        .catch((error) => {
          _this.logError(error);
        })
        .finally(() => {
          _this.pageLoading = false;
        });
    },
    updatePropertySetting() {
      const _this = this;
      _this.pageLoading = true;
      _this.$store
        .dispatch(PATCH, { url: "setting/property", data: _this.setting })
        .catch((error) => {
          _this.logError(error);
        })
        .finally(() => {
          _this.pageLoading = false;
        });
    },
    handlePageChange(page) {
      this.propertPageLoading = true;
      this.currentPage = page;
      setTimeout(() => {
        this.propertPageLoading = false;
      }, 1000);
    },
  },
  mounted() {
    this.getPropertySetting();
  },
  computed: {
    pageCount() {
      return Math.ceil(this.locationList.length / this.itemsPerPage);
    },
    paginatedLocationList() {
      const start = (this.currentPage - 1) * this.itemsPerPage;
      const end = start + this.itemsPerPage;
      return this.locationList.slice(start, end);
    },
  },
};
</script>
